// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

// import buidlLogo from "../images/final_logo_white.svg"

const GdocWrapper = styled.p`
  ${tw`bg-gray-100 border border-solid border-gray-400 px-4 py-2 text-sm `};
  font-weight: 300;
`
const GLink = styled.a`
  ${tw`no-underline`};
  color: hsl(22, 78%, 55%);
`

const Gdoc = ({ gDocLink }) => (
  <GdocWrapper>
    We welcome edits and annotations of all our content. 
    Annotate or edit this article <GLink href={gDocLink}> here</GLink> 
  </GdocWrapper>
)

Gdoc.propTypes = {
  gDocLink: PropTypes.string,
}

Gdoc.defaultProps = {
  gDocLink: `https://theBUIDL.com`,
}

export default Gdoc
