import React, { Component } from "react"
import { graphql } from "gatsby"
import tw from "tailwind.macro"
import SEO from "./seo"
import "./layout.css"
import "./markdown.css"

import Layout from "./layout"
import Gdoc from "./gdoc"
import styled from "styled-components"
import SyntaxHighlighter from "react-syntax-highlighter"

import PortableText from "@sanity/block-content-to-react"
import urlBuilder from "@sanity/image-url"

const urlFor = source =>
  urlBuilder({ projectId: "472uac00", dataset: "production" }).image(source)

const serializers = {
  types: {
    image: props => (
      <figure>
        <img
          src={urlFor(props.node.asset)
            .width(600)
            .url()}
          alt={props.node.caption}
          // ? Should there be a dedicated caption field?
        />

        <figcaption>{props.node.caption}</figcaption>
      </figure>
    ),
    code: ({ node = {} }) => {
      const { code, language } = node
      if (!code) {
        return null
      }
      return (
        <SyntaxHighlighter language={language || "text"}>
          {code}
        </SyntaxHighlighter>
      )
    },
  },
}

//end json stuff

export const query = graphql`
  query ArticleQuery($slug: String!) {
    sanityArticle(slug: { current: { eq: $slug } }) {
      title
      sponsored
      featured
      publishedAt(formatString: "MMMM DD, YYYY")
      tweet
      slug {
        current
      }
      _rawBody
      categories {
        title
      }
      mainImage {
        asset {
          fixed(width: 900) {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
        }
      }
      author {
        name
        image {
          asset {
            fixed(width: 50, height: 50) {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
          }
        }
      }
      id
      gdocsURL
    }
  }
`

const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-width: 350px;
  max-width: 900px;
  grid-template-rows: auto;
  grid-gap: 15px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  h1 {
    justify-self: center;
    font-weight: 300;
    ${tw`text-gray-800`};
  }
`

const MainImageDiv = styled.div`
  display: block;
  overflow: hidden;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

const Aside = styled.aside`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 350px;
  img {
    ${tw`rounded-full`};
  }
  .author {
    display: flex;
    align-items: center;
  }
  span {
    ${tw`text-gray-600 px-4`};
  }
`
const Tags = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  span {
    ${tw`inline-block bg-gray-400 mx-2 my-2 rounded-full px-2 py-1 text-xs font-bold text-gray-800`};
  }
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  blockquote {
    ${tw`text-gray-600 italic text-3xl`};
  }
  h1,
  h2,
  h3 {
    font-weight: 400;
    ${tw`text-gray-800`};
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

export default class articleLayout extends Component {
  render() {
    const { sanityArticle } = this.props.data
    return (
      <Layout>
        <ArticleContainer>
          <SEO title={sanityArticle.title} />
          <MainImageDiv>
            <img
              src={sanityArticle.mainImage.asset.fixed.src}
              alt={sanityArticle.title}
            />
          </MainImageDiv>
          <h1>{sanityArticle.title}</h1>
          <Aside>
            <div className="author">
              <img
                src={sanityArticle.author.image.asset.fixed.src}
                alt={sanityArticle.author.name}
              />
              {/* <img
                src={sanityArticle.author.image.asset.fixed.srcWebp}
                alt={sanityArticle.author.name}
              /> */}
              <span>{sanityArticle.author.name}</span>
            </div>
            <span>{sanityArticle.publishedAt}</span>
            <Tags>
              {sanityArticle.categories.map(({ title }) => (
                <span>{title}</span>
              ))}
            </Tags>
          </Aside>
          <Gdoc gDocLink={sanityArticle.gdocsURL}></Gdoc>
          <Article>
            <PortableText
              blocks={sanityArticle._rawBody}
              serializers={serializers}
              className="markdown"
            />
          </Article>
        </ArticleContainer>
      </Layout>
    )
  }
}
